import _fs from "fs";
import _path from "path";
import _file from "file";
import _system from "system";
import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};
var process = _process;

/* Jison generated parser */
var jsonlint = function () {
  var parser = {
    trace: function trace() {},
    yy: {},
    symbols_: {
      "error": 2,
      "JSONString": 3,
      "STRING": 4,
      "JSONNumber": 5,
      "NUMBER": 6,
      "JSONNullLiteral": 7,
      "NULL": 8,
      "JSONBooleanLiteral": 9,
      "TRUE": 10,
      "FALSE": 11,
      "JSONText": 12,
      "JSONValue": 13,
      "EOF": 14,
      "JSONObject": 15,
      "JSONArray": 16,
      "{": 17,
      "}": 18,
      "JSONMemberList": 19,
      "JSONMember": 20,
      ":": 21,
      ",": 22,
      "[": 23,
      "]": 24,
      "JSONElementList": 25,
      "$accept": 0,
      "$end": 1
    },
    terminals_: {
      2: "error",
      4: "STRING",
      6: "NUMBER",
      8: "NULL",
      10: "TRUE",
      11: "FALSE",
      14: "EOF",
      17: "{",
      18: "}",
      21: ":",
      22: ",",
      23: "[",
      24: "]"
    },
    productions_: [0, [3, 1], [5, 1], [7, 1], [9, 1], [9, 1], [12, 2], [13, 1], [13, 1], [13, 1], [13, 1], [13, 1], [13, 1], [15, 2], [15, 3], [20, 3], [19, 1], [19, 3], [16, 2], [16, 3], [25, 1], [25, 3]],
    performAction: function anonymous(yytext, yyleng, yylineno, yy, yystate, $$, _$) {
      var $0 = $$.length - 1;

      switch (yystate) {
        case 1:
          // replace escaped characters with actual character
          (this || _global).$ = yytext.replace(/\\(\\|")/g, "$" + "1").replace(/\\n/g, "\n").replace(/\\r/g, "\r").replace(/\\t/g, "\t").replace(/\\v/g, "\x0B").replace(/\\f/g, "\f").replace(/\\b/g, "\b");
          break;

        case 2:
          (this || _global).$ = Number(yytext);
          break;

        case 3:
          (this || _global).$ = null;
          break;

        case 4:
          (this || _global).$ = true;
          break;

        case 5:
          (this || _global).$ = false;
          break;

        case 6:
          return (this || _global).$ = $$[$0 - 1];
          break;

        case 13:
          (this || _global).$ = {};
          break;

        case 14:
          (this || _global).$ = $$[$0 - 1];
          break;

        case 15:
          (this || _global).$ = [$$[$0 - 2], $$[$0]];
          break;

        case 16:
          (this || _global).$ = {};
          (this || _global).$[$$[$0][0]] = $$[$0][1];
          break;

        case 17:
          (this || _global).$ = $$[$0 - 2];
          $$[$0 - 2][$$[$0][0]] = $$[$0][1];
          break;

        case 18:
          (this || _global).$ = [];
          break;

        case 19:
          (this || _global).$ = $$[$0 - 1];
          break;

        case 20:
          (this || _global).$ = [$$[$0]];
          break;

        case 21:
          (this || _global).$ = $$[$0 - 2];
          $$[$0 - 2].push($$[$0]);
          break;
      }
    },
    table: [{
      3: 5,
      4: [1, 12],
      5: 6,
      6: [1, 13],
      7: 3,
      8: [1, 9],
      9: 4,
      10: [1, 10],
      11: [1, 11],
      12: 1,
      13: 2,
      15: 7,
      16: 8,
      17: [1, 14],
      23: [1, 15]
    }, {
      1: [3]
    }, {
      14: [1, 16]
    }, {
      14: [2, 7],
      18: [2, 7],
      22: [2, 7],
      24: [2, 7]
    }, {
      14: [2, 8],
      18: [2, 8],
      22: [2, 8],
      24: [2, 8]
    }, {
      14: [2, 9],
      18: [2, 9],
      22: [2, 9],
      24: [2, 9]
    }, {
      14: [2, 10],
      18: [2, 10],
      22: [2, 10],
      24: [2, 10]
    }, {
      14: [2, 11],
      18: [2, 11],
      22: [2, 11],
      24: [2, 11]
    }, {
      14: [2, 12],
      18: [2, 12],
      22: [2, 12],
      24: [2, 12]
    }, {
      14: [2, 3],
      18: [2, 3],
      22: [2, 3],
      24: [2, 3]
    }, {
      14: [2, 4],
      18: [2, 4],
      22: [2, 4],
      24: [2, 4]
    }, {
      14: [2, 5],
      18: [2, 5],
      22: [2, 5],
      24: [2, 5]
    }, {
      14: [2, 1],
      18: [2, 1],
      21: [2, 1],
      22: [2, 1],
      24: [2, 1]
    }, {
      14: [2, 2],
      18: [2, 2],
      22: [2, 2],
      24: [2, 2]
    }, {
      3: 20,
      4: [1, 12],
      18: [1, 17],
      19: 18,
      20: 19
    }, {
      3: 5,
      4: [1, 12],
      5: 6,
      6: [1, 13],
      7: 3,
      8: [1, 9],
      9: 4,
      10: [1, 10],
      11: [1, 11],
      13: 23,
      15: 7,
      16: 8,
      17: [1, 14],
      23: [1, 15],
      24: [1, 21],
      25: 22
    }, {
      1: [2, 6]
    }, {
      14: [2, 13],
      18: [2, 13],
      22: [2, 13],
      24: [2, 13]
    }, {
      18: [1, 24],
      22: [1, 25]
    }, {
      18: [2, 16],
      22: [2, 16]
    }, {
      21: [1, 26]
    }, {
      14: [2, 18],
      18: [2, 18],
      22: [2, 18],
      24: [2, 18]
    }, {
      22: [1, 28],
      24: [1, 27]
    }, {
      22: [2, 20],
      24: [2, 20]
    }, {
      14: [2, 14],
      18: [2, 14],
      22: [2, 14],
      24: [2, 14]
    }, {
      3: 20,
      4: [1, 12],
      20: 29
    }, {
      3: 5,
      4: [1, 12],
      5: 6,
      6: [1, 13],
      7: 3,
      8: [1, 9],
      9: 4,
      10: [1, 10],
      11: [1, 11],
      13: 30,
      15: 7,
      16: 8,
      17: [1, 14],
      23: [1, 15]
    }, {
      14: [2, 19],
      18: [2, 19],
      22: [2, 19],
      24: [2, 19]
    }, {
      3: 5,
      4: [1, 12],
      5: 6,
      6: [1, 13],
      7: 3,
      8: [1, 9],
      9: 4,
      10: [1, 10],
      11: [1, 11],
      13: 31,
      15: 7,
      16: 8,
      17: [1, 14],
      23: [1, 15]
    }, {
      18: [2, 17],
      22: [2, 17]
    }, {
      18: [2, 15],
      22: [2, 15]
    }, {
      22: [2, 21],
      24: [2, 21]
    }],
    defaultActions: {
      16: [2, 6]
    },
    parseError: function parseError(str, hash) {
      throw new Error(str);
    },
    parse: function parse(input) {
      var self = this || _global,
          stack = [0],
          vstack = [null],
          // semantic value stack
      lstack = [],
          // location stack
      table = (this || _global).table,
          yytext = "",
          yylineno = 0,
          yyleng = 0,
          recovering = 0,
          TERROR = 2,
          EOF = 1; //this.reductionCount = this.shiftCount = 0;

      (this || _global).lexer.setInput(input);

      (this || _global).lexer.yy = (this || _global).yy;
      (this || _global).yy.lexer = (this || _global).lexer;
      if (typeof (this || _global).lexer.yylloc == "undefined") (this || _global).lexer.yylloc = {};
      var yyloc = (this || _global).lexer.yylloc;
      lstack.push(yyloc);
      if (typeof (this || _global).yy.parseError === "function") (this || _global).parseError = (this || _global).yy.parseError;

      function popStack(n) {
        stack.length = stack.length - 2 * n;
        vstack.length = vstack.length - n;
        lstack.length = lstack.length - n;
      }

      function lex() {
        var token;
        token = self.lexer.lex() || 1; // $end = 1
        // if token isn't its numeric value, convert

        if (typeof token !== "number") {
          token = self.symbols_[token] || token;
        }

        return token;
      }

      var symbol,
          preErrorSymbol,
          state,
          action,
          a,
          r,
          yyval = {},
          p,
          len,
          newState,
          expected;

      while (true) {
        // retreive state number from top of stack
        state = stack[stack.length - 1]; // use default actions if available

        if ((this || _global).defaultActions[state]) {
          action = (this || _global).defaultActions[state];
        } else {
          if (symbol == null) symbol = lex(); // read action for current state and first input

          action = table[state] && table[state][symbol];
        } // handle parse error


        _handle_error: if (typeof action === "undefined" || !action.length || !action[0]) {
          if (!recovering) {
            // Report error
            expected = [];

            for (p in table[state]) if ((this || _global).terminals_[p] && p > 2) {
              expected.push("'" + (this || _global).terminals_[p] + "'");
            }

            var errStr = "";

            if ((this || _global).lexer.showPosition) {
              errStr = "Parse error on line " + (yylineno + 1) + ":\n" + (this || _global).lexer.showPosition() + "\nExpecting " + expected.join(", ") + ", got '" + (this || _global).terminals_[symbol] + "'";
            } else {
              errStr = "Parse error on line " + (yylineno + 1) + ": Unexpected " + (symbol == 1
              /*EOF*/
              ? "end of input" : "'" + ((this || _global).terminals_[symbol] || symbol) + "'");
            }

            this.parseError(errStr, {
              text: (this || _global).lexer.match,
              token: (this || _global).terminals_[symbol] || symbol,
              line: (this || _global).lexer.yylineno,
              loc: yyloc,
              expected: expected
            });
          } // just recovered from another error


          if (recovering == 3) {
            if (symbol == EOF) {
              throw new Error(errStr || "Parsing halted.");
            } // discard current lookahead and grab another


            yyleng = (this || _global).lexer.yyleng;
            yytext = (this || _global).lexer.yytext;
            yylineno = (this || _global).lexer.yylineno;
            yyloc = (this || _global).lexer.yylloc;
            symbol = lex();
          } // try to recover from error


          while (1) {
            // check for error recovery rule in this state
            if (TERROR.toString() in table[state]) {
              break;
            }

            if (state == 0) {
              throw new Error(errStr || "Parsing halted.");
            }

            popStack(1);
            state = stack[stack.length - 1];
          }

          preErrorSymbol = symbol; // save the lookahead token

          symbol = TERROR; // insert generic error symbol as new lookahead

          state = stack[stack.length - 1];
          action = table[state] && table[state][TERROR];
          recovering = 3; // allow 3 real symbols to be shifted before reporting a new error
        } // this shouldn't happen, unless resolve defaults are off


        if (action[0] instanceof Array && action.length > 1) {
          throw new Error("Parse Error: multiple actions possible at state: " + state + ", token: " + symbol);
        }

        switch (action[0]) {
          case 1:
            // shift
            //this.shiftCount++;
            stack.push(symbol);
            vstack.push((this || _global).lexer.yytext);
            lstack.push((this || _global).lexer.yylloc);
            stack.push(action[1]); // push state

            symbol = null;

            if (!preErrorSymbol) {
              // normal execution/no error
              yyleng = (this || _global).lexer.yyleng;
              yytext = (this || _global).lexer.yytext;
              yylineno = (this || _global).lexer.yylineno;
              yyloc = (this || _global).lexer.yylloc;
              if (recovering > 0) recovering--;
            } else {
              // error just occurred, resume old lookahead f/ before error
              symbol = preErrorSymbol;
              preErrorSymbol = null;
            }

            break;

          case 2:
            // reduce
            //this.reductionCount++;
            len = (this || _global).productions_[action[1]][1]; // perform semantic action

            yyval.$ = vstack[vstack.length - len]; // default to $$ = $1
            // default location, uses first token for firsts, last for lasts

            yyval._$ = {
              first_line: lstack[lstack.length - (len || 1)].first_line,
              last_line: lstack[lstack.length - 1].last_line,
              first_column: lstack[lstack.length - (len || 1)].first_column,
              last_column: lstack[lstack.length - 1].last_column
            };
            r = (this || _global).performAction.call(yyval, yytext, yyleng, yylineno, (this || _global).yy, action[1], vstack, lstack);

            if (typeof r !== "undefined") {
              return r;
            } // pop off stack


            if (len) {
              stack = stack.slice(0, -1 * len * 2);
              vstack = vstack.slice(0, -1 * len);
              lstack = lstack.slice(0, -1 * len);
            }

            stack.push((this || _global).productions_[action[1]][0]); // push nonterminal (reduce)

            vstack.push(yyval.$);
            lstack.push(yyval._$); // goto new state = table[STATE][NONTERMINAL]

            newState = table[stack[stack.length - 2]][stack[stack.length - 1]];
            stack.push(newState);
            break;

          case 3:
            // accept
            return true;
        }
      }

      return true;
    }
  };
  /* Jison generated lexer */

  var lexer = function () {
    var lexer = {
      EOF: 1,
      parseError: function parseError(str, hash) {
        if ((this || _global).yy.parseError) {
          (this || _global).yy.parseError(str, hash);
        } else {
          throw new Error(str);
        }
      },
      setInput: function (input) {
        (this || _global)._input = input;
        (this || _global)._more = (this || _global)._less = (this || _global).done = false;
        (this || _global).yylineno = (this || _global).yyleng = 0;
        (this || _global).yytext = (this || _global).matched = (this || _global).match = "";
        (this || _global).conditionStack = ["INITIAL"];
        (this || _global).yylloc = {
          first_line: 1,
          first_column: 0,
          last_line: 1,
          last_column: 0
        };
        return this || _global;
      },
      input: function () {
        var ch = (this || _global)._input[0];
        (this || _global).yytext += ch;
        (this || _global).yyleng++;
        (this || _global).match += ch;
        (this || _global).matched += ch;
        var lines = ch.match(/\n/);
        if (lines) (this || _global).yylineno++;
        (this || _global)._input = (this || _global)._input.slice(1);
        return ch;
      },
      unput: function (ch) {
        (this || _global)._input = ch + (this || _global)._input;
        return this || _global;
      },
      more: function () {
        (this || _global)._more = true;
        return this || _global;
      },
      less: function (n) {
        (this || _global)._input = (this || _global).match.slice(n) + (this || _global)._input;
      },
      pastInput: function () {
        var past = (this || _global).matched.substr(0, (this || _global).matched.length - (this || _global).match.length);

        return (past.length > 20 ? "..." : "") + past.substr(-20).replace(/\n/g, "");
      },
      upcomingInput: function () {
        var next = (this || _global).match;

        if (next.length < 20) {
          next += (this || _global)._input.substr(0, 20 - next.length);
        }

        return (next.substr(0, 20) + (next.length > 20 ? "..." : "")).replace(/\n/g, "");
      },
      showPosition: function () {
        var pre = this.pastInput();
        var c = new Array(pre.length + 1).join("-");
        return pre + this.upcomingInput() + "\n" + c + "^";
      },
      next: function () {
        if ((this || _global).done) {
          return (this || _global).EOF;
        }

        if (!(this || _global)._input) (this || _global).done = true;
        var token, match, tempMatch, index, col, lines;

        if (!(this || _global)._more) {
          (this || _global).yytext = "";
          (this || _global).match = "";
        }

        var rules = this._currentRules();

        for (var i = 0; i < rules.length; i++) {
          tempMatch = (this || _global)._input.match((this || _global).rules[rules[i]]);

          if (tempMatch && (!match || tempMatch[0].length > match[0].length)) {
            match = tempMatch;
            index = i;
            if (!(this || _global).options.flex) break;
          }
        }

        if (match) {
          lines = match[0].match(/\n.*/g);
          if (lines) (this || _global).yylineno += lines.length;
          (this || _global).yylloc = {
            first_line: (this || _global).yylloc.last_line,
            last_line: (this || _global).yylineno + 1,
            first_column: (this || _global).yylloc.last_column,
            last_column: lines ? lines[lines.length - 1].length - 1 : (this || _global).yylloc.last_column + match[0].length
          };
          (this || _global).yytext += match[0];
          (this || _global).match += match[0];
          (this || _global).yyleng = (this || _global).yytext.length;
          (this || _global)._more = false;
          (this || _global)._input = (this || _global)._input.slice(match[0].length);
          (this || _global).matched += match[0];
          token = (this || _global).performAction.call(this || _global, (this || _global).yy, this || _global, rules[index], (this || _global).conditionStack[(this || _global).conditionStack.length - 1]);
          if ((this || _global).done && (this || _global)._input) (this || _global).done = false;
          if (token) return token;else return;
        }

        if ((this || _global)._input === "") {
          return (this || _global).EOF;
        } else {
          this.parseError("Lexical error on line " + ((this || _global).yylineno + 1) + ". Unrecognized text.\n" + this.showPosition(), {
            text: "",
            token: null,
            line: (this || _global).yylineno
          });
        }
      },
      lex: function lex() {
        var r = this.next();

        if (typeof r !== "undefined") {
          return r;
        } else {
          return this.lex();
        }
      },
      begin: function begin(condition) {
        (this || _global).conditionStack.push(condition);
      },
      popState: function popState() {
        return (this || _global).conditionStack.pop();
      },
      _currentRules: function _currentRules() {
        return (this || _global).conditions[(this || _global).conditionStack[(this || _global).conditionStack.length - 1]].rules;
      },
      topState: function () {
        return (this || _global).conditionStack[(this || _global).conditionStack.length - 2];
      },
      pushState: function begin(condition) {
        this.begin(condition);
      }
    };
    lexer.options = {};

    lexer.performAction = function anonymous(yy, yy_, $avoiding_name_collisions, YY_START) {
      var YYSTATE = YY_START;

      switch ($avoiding_name_collisions) {
        case 0:
          /* skip whitespace */
          break;

        case 1:
          return 6;
          break;

        case 2:
          yy_.yytext = yy_.yytext.substr(1, yy_.yyleng - 2);
          return 4;
          break;

        case 3:
          return 17;
          break;

        case 4:
          return 18;
          break;

        case 5:
          return 23;
          break;

        case 6:
          return 24;
          break;

        case 7:
          return 22;
          break;

        case 8:
          return 21;
          break;

        case 9:
          return 10;
          break;

        case 10:
          return 11;
          break;

        case 11:
          return 8;
          break;

        case 12:
          return 14;
          break;

        case 13:
          return "INVALID";
          break;
      }
    };

    lexer.rules = [/^(?:\s+)/, /^(?:(-?([0-9]|[1-9][0-9]+))(\.[0-9]+)?([eE][-+]?[0-9]+)?\b)/, /^(?:"(?:\\[\\"bfnrt/]|\\u[a-fA-F0-9]{4}|[^\\\0-\x09\x0a-\x1f"])*")/, /^(?:\{)/, /^(?:\})/, /^(?:\[)/, /^(?:\])/, /^(?:,)/, /^(?::)/, /^(?:true\b)/, /^(?:false\b)/, /^(?:null\b)/, /^(?:$)/, /^(?:.)/];
    lexer.conditions = {
      "INITIAL": {
        "rules": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
        "inclusive": true
      }
    };
    ;
    return lexer;
  }();

  parser.lexer = lexer;
  return parser;
}();

{
  exports.parser = jsonlint;

  exports.parse = function () {
    return jsonlint.parse.apply(jsonlint, arguments);
  };

  exports.main = function commonjsMain(args) {
    if (!args[1]) throw new Error("Usage: " + args[0] + " FILE");

    if (typeof process !== "undefined") {
      var source = _fs.readFileSync(_path.join(process.cwd(), args[1]), "utf8");
    } else {
      var cwd = _file.path(_file.cwd());

      var source = cwd.join(args[1]).read({
        charset: "utf-8"
      });
    }

    return exports.parser.parse(source);
  };

  if (undefined === module) {
    exports.main(typeof process !== "undefined" ? process.argv.slice(1) : _system.args);
  }
}
export default module.exports;
export const parser = module.exports.parser,
      parse = module.exports.parse,
      main = module.exports.main;